const travelPlaces = [
  {
    id: 1,
    img: "japan.png",
    alt: "photo from far away of Mount Fuji",
    country: "Japan",
    link: "https://www.google.nl/maps/place/Fuji/@35.3606422,138.7186086,15z/data=!3m1!4b1!4m5!3m4!1s0x6019629a42fdc899:0xa6a1fcc916f3a4df!8m2!3d35.3606255!4d138.7273634",
    title: "Mount Fuji",
    date: "12 Jan, 2021 - 24 Jan, 2021",
    description:
      "Mount Fuji is the tallest mountain in Japan, standing at 3,776 meters (12,380 feet). Mount Fuji is the single most popular tourist site in Japan, for both Japanese and foreign tourists.",
  },
  {
    id: 2,
    img: "australia.png",
    alt: "sky view photo op the Opera House in Sydney",
    country: "Australia",
    link: "https://www.google.nl/maps/place/Sydney+Opera+House/@-33.8567799,151.2110695,17z/data=!3m2!4b1!5s0x6b12ae67d234a27f:0xd6d4e9380ca1e32f!4m5!3m4!1s0x6b12ae665e892fdd:0x3133f8d75a1ac251!8m2!3d-33.8567844!4d151.2152967",
    title: "Sydney Opera House",
    date: "27 May, 2021 - 8 Jun, 2021",
    description:
      "The Sydney Opera House is a multi-venue performing arts centre in Sydney. Located on the banks of the Sydney Harbour, it is often regarded as one of the 20th century's most famous and distinctive buildings",
  },
  {
    id: 3,
    img: "norway.png",
    alt: "photo of the Geiranger Fjord with a little boat on the water",
    country: "Norway",
    link: "https://www.google.nl/maps/place/Geirangerfjord/@62.1049113,7.0051744,12z/data=!3m1!4b1!4m5!3m4!1s0x46169d427b268c51:0xb8c99540dcc397fe!8m2!3d62.101506!4d7.0940817",
    title: "Geirangerfjord",
    date: "01 Oct, 2021 - 18 Nov, 2021",
    description:
      "The Geiranger Fjord is a fjord in the Sunnmøre region of Møre og Romsdal county, Norway. It is located entirely in the Stranda Municipality.",
  },
];

export default travelPlaces;
